import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
  const [navToggler, setNavToggler] = useState(false);
  const closeNavbar = () => setNavToggler(false);

  const handleLinkClick = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }

    closeNavbar();
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="navbar-content">
          <div className="brand-and-toggler flex flex-sb">
            <Link to="/">
              <img src="logo.png" alt="Logo" className="navbarLogo" />
            </Link>
            <button
              type="button"
              className="navbar-open-btn text-white"
              onClick={() => setNavToggler(!navToggler)}
            >
              <FaBars size={30} />
            </button>

            <div
              className={
                navToggler
                  ? "navbar-collapse show-navbar-collapse"
                  : "navbar-collapse"
              }
            >
              <button
                type="button"
                className="navbar-close-btn text-white"
                onClick={closeNavbar}
              >
                <FaTimes size={30} />
              </button>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    onClick={(e) => handleLinkClick(e, "header")}
                    className="nav-link text-white ls-1 fw-6 fs-22"
                  >
                    ANASAYFA
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    onClick={(e) => handleLinkClick(e, "about")}
                    className="nav-link text-white ls-1  fw-6 fs-22"
                  >
                    HAKKIMIZDA
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    onClick={(e) => handleLinkClick(e, "work")}
                    className="nav-link text-white ls-1  fw-6 fs-22"
                  >
                    PROJELERİMİZ
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/"
                    onClick={(e) => handleLinkClick(e, "contact")}
                    className="nav-link text-white ls-1  fw-6 fs-22"
                  >
                    İLETİŞİM
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
