import pattern_00 from "../assets/images/pattern_00.png";
import pattern_1 from "../assets/images/pattern_1.png";
import services_img_1 from "../assets/images/bandotakımı.jpg";
import services_img_2 from "../assets/images/services_img_2.svg";
import services_img_3 from "../assets/images/services_img_3.svg";
import services_img_4 from "../assets/images/services_img_4.svg";
import services_img_5 from "../assets/images/services_img_5.svg";
import services_img_6 from "../assets/images/services_img_6.svg";
import work_img_1 from "../assets/images/img/foto2.png";
import work_img_2 from "../assets/images/img/foto4.jpeg";
import work_img_3 from "../assets/images/img/foto7.jpeg";
import work_img_4 from "../assets/images/img/foto9.JPG";
import work_img_5 from "../assets/images/img/foto9.JPG";
import work_img_6 from "../assets/images/img/foto16.jpg";
import work_img_7 from "../assets/images/img/foto2.png";
import work_img_8 from "../assets/images/img/foto4.jpeg";
import work_img_9 from "../assets/images/img/foto7.jpeg";
import work_img_10 from "../assets/images/img/foto9.JPG";
import work_img_11 from "../assets/images/img/foto9.JPG";
import work_img_12 from "../assets/images/img/foto16.jpg";
import About_Alex from "../assets/images/About_Alex.jpg";

const images = {
  pattern_00,
  pattern_1,
  services_img_1,
  services_img_2,
  services_img_3,
  services_img_4,
  services_img_5,
  services_img_6,
  work_img_1,
  work_img_2,
  work_img_3,
  work_img_4,
  work_img_5,
  work_img_6,
  work_img_7,
  work_img_8,
  work_img_9,
  work_img_10,
  work_img_11,
  work_img_12,
  About_Alex,
};
export default images;
