import React, { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import "./About.css";
import images from "../../constants/images";

const About = () => {
  const containerRef = useRef(null);
  const controls = useAnimation();

  const handleScroll = () => {
    const containerOffset = containerRef.current.offsetTop;
    const scrollPosition = window.scrollY + window.innerHeight;

    if (scrollPosition > containerOffset) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const imageVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
  };

  const textVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <div className="about section-p" id="about" ref={containerRef}>
      <div className="container">
        <div className="about-content">
          <div className="about-grid grid">
            <motion.img
              src={images.About_Alex}
              alt="bandoRed"
              className="about-img mx-auto"
              variants={imageVariants}
              initial="hidden"
              animate={controls}
              transition={{ duration: 0.8 }}
            />
            <div className="section-title">
              <motion.h3
                className="text-brown"
                variants={textVariants}
                initial="hidden"
                animate={controls}
                transition={{ duration: 0.8 }}
              >
                Akademi Sanat Organizasyon
              </motion.h3>
              <motion.p
                className="text mx-auto"
                variants={textVariants}
                initial="hidden"
                animate={controls}
                transition={{ duration: 0.8 }}
              >
                Her türlü özel gün ve eğlencelerinizde beklentilerinizin
                ötesinde sanatsal çözümler üreten Akademi Sanat Organizasyon,
                müzik grupları, gösteri ekipleri ve birbirinden özel konsept
                programları ile eğlence sektöründe tecrübeli ve güvenilir
                hizmetler sunan bir sanat organizasyonudur.
              </motion.p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
