import React from "react";
import "./Footer.css";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer bg-dark section-p" id="footer">
      <div className="container">
        <div className="section-title">
          <h3 className="text-brown">
            Bizi <span className="text-white">Takip </span> Edin
          </h3>
        </div>
        <div className="footer-content">
          <ul className="footer-social-links flex flex-c">
            <li>
              <a href="/" className="text-white">
                <FaFacebookF size={28} />
              </a>
            </li>
            <li>
              <a href="/" className="text-white">
                <FaTwitter size={28} />
              </a>
            </li>
            <li>
              <a href="/" className="text-white">
                <FaInstagram size={28} />
              </a>
            </li>
          </ul>
          <p className="text-center fs-14 text-white op-07 fw-3 ls-1 reserved-text">
            Tüm Hakları Saklıdır &copy; 2024
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
