import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { works } from "../../constants/data";
import { useInView } from "react-intersection-observer";
import "./Work.css";
import { BsPlusLg } from "react-icons/bs";
import { ImCancelCircle } from "react-icons/im";

const Work = () => {
  const [imageModal, setImageModal] = useState(false);
  const [imageSource, setImageSource] = useState("");

  const setImageOnModal = (src) => {
    setImageModal(true);
    setImageSource(src);
  };

  const controls = useAnimation();
  const [ref, inView] = useInView(); // Use useInView hook

  useEffect(() => {
    if (inView) {
      controls.start((i) => ({
        opacity: 1,
        y: 0,
        transition: {
          delay: i * 0.25,
          duration: 0.5,
          type: "spring",
          stiffness: 120,
        },
      }));
    }
  }, [controls, inView]);
  return (
    <div className="work section-p bg-grey" id="work" ref={ref}>
      <div className={imageModal ? "image-box show-image-box" : "image-box"}>
        <div className="image-box-content">
          <img src={imageSource} alt="" />
          <span
            className="image-box-close-btn"
            onClick={() => setImageModal(false)}
          >
            <ImCancelCircle size={30} />
          </span>
        </div>
      </div>

      <div className="container">
        <div className="work-content">
          <div className="section-title">
            <h3 className="text-brown">
              Projelerimiz <span className="text-dark"></span>
            </h3>
            <p className="text">
              Hayalinizdeki organizasyonu söyleyin gerisini biz yapalım.
            </p>
          </div>

          <div className="work-list grid">
            {works.map((work, index) => {
              return (
                <motion.div
                  className="work-item text-center"
                  key={index}
                  onClick={() => setImageOnModal(work.image)}
                  custom={index}
                  initial={{ opacity: 0, y: -50 }}
                  animate={controls}
                >
                  <img src={work.image} alt="" />
                  <span className="work-item-icon">
                    <BsPlusLg size={38} className="text-brown" />
                  </span>
                </motion.div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
