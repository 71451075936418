import React from "react";
import "./Contact.css";
import { FaWhatsapp } from "react-icons/fa";
import { CgMail } from "react-icons/cg";
import { BsFillTelephoneFill } from "react-icons/bs";
const Contact = () => {
  return (
    <div className="contact section-p" id="contact">
      <div className="container ">
        <div className="section-title">
          <h3 className="text-brown">İletişim</h3>
        </div>
        <div className="contact-section contactContainer">
          <div className="icons">
            <a
              href="tel:+05325430852"
              target="_blank"
              rel="noreferrer"
              className="text-dark flex "
            >
              <BsFillTelephoneFill className="icon" size={32} />
              <span>
                <p>+0532 543 08 52 </p>
              </span>
            </a>
          </div>
          <div className="icons">
            <a
              href="mailto:akademi_sanat@gmail.com"
              target="_blank"
              rel="noreferrer"
              className="text-dark flex"
            >
              <CgMail className="icon" size={42} />

              <p>İnfo@akademisanat.com</p>
            </a>
          </div>
          <div className="icons">
            <a
              href="https://wa.me/905325430852"
              target="_blank"
              rel="noopener noreferrer"
              className="text-dark flex  "
            >
              <FaWhatsapp className="icon" size={36} />

              <p>WhatsApp</p>
            </a>
          </div>
        </div>
        <div className="map-container">
          {/* Google Maps Embed iframe */}
          <iframe
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1598820.6527554921!2d25.692637163638096!3d38.494642582662!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bbd862a762cacd%3A0x628cbba1a59ce8fe!2zxLB6bWly!5e0!3m2!1str!2str!4v1706096578484!5m2!1str!2str"
            width="600"
            height="300"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
