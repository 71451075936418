import React from "react";
import "./Header.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Header = () => {
  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const handleLinkClick = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  return (
    <div className="header flex flex-col" id="header">
      <Navbar />
      <motion.div
        className="container flex"
        initial="hidden"
        animate="visible"
        variants={variants}
        transition={{ opacity: { duration: 1, delay: 0.5 } }}
      >
        <div className="header-content">
          <h2 className=" text-white op-07 fw-6 ls-2"></h2>
          <h1 className="text-white fw-6 header-title">
            <span className="text-brown">BANDO RED</span> {""}
            Hayalinizdeki organizasyonu bize anlatın Gerisini biz yapalım
          </h1>
          <div className="btn-groups flex">
            <Link
              onClick={(e) => handleLinkClick(e, "work")}
              className="nav-link text-white ls-1  fw-6 fs-22"
            >
              <button type="button" className="btn-item bg-brown fw-4 ls-2">
                Projelerimiz
              </button>
            </Link>
            <Link
              onClick={(e) => handleLinkClick(e, "contact")}
              className="nav-link text-white ls-1  fw-6 fs-22"
            >
              <button type="button" className="btn-item bg-dark fw-4 ls-2">
                Bize Ulaşın
              </button>
            </Link>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Header;
